import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDashboardStats } from 'api/hooks';
import { Paper, Skeleton, Table, UnstyledButton, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import _ from 'lodash';
import { randomId } from '@mantine/hooks';

import { EmptyState } from 'components/ui-messages';
import classNames from 'classnames';
import { ChartTitle } from './components';

function RowSkeleton({ categories }) {
  return (
    <Table.Tr>
      <Table.Td align="center">
        <Skeleton height={20} width={50} radius="xl" />
      </Table.Td>
      <Table.Td>
        <Skeleton height={20} width={300} radius="xl" />
      </Table.Td>
      {_.map(categories, (c) => (
        <Table.Td key={randomId() + c.code} align="left">
          <Skeleton height={20} width={50} radius="xl" />
        </Table.Td>
      ))}
    </Table.Tr>
  );
}
function InstitutionsTopStats({ queryKey, filter, scrollable }) {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);

  const categoryOptions = useMemo(() => {
    return [
      {
        code: 'C1',
        value: 'C1',
        name: t('stats.categories-top.info-indices'),
        shortName: t('stats.categories-top.info-indices-short'),
      },
      {
        code: 'C2',
        value: 'C2',
        name: t('stats.categories-top.interaction'),
        shortName: t('stats.categories-top.interaction-short'),
      },
      {
        code: 'C3',
        value: 'C3',
        name: t('stats.categories-top.trust-indices'),
        shortName: t('stats.categories-top.trust-indices-short'),
      },
      {
        code: 'C4',
        value: 'C4',
        name: t('stats.categories-top.facilities'),
        shortName: t('stats.categories-top.facilities-short'),
      },
      {
        code: 'C5',
        value: 'C5',
        name: t('stats.categories-top.unofficial-payments'),
        showPercentage: true,
        shortName: t('stats.categories-top.unofficial-payments-short'),
      },
    ];
  }, [t]);

  const [sort, setSort] = useState({ code: 'C1', type: 'DESC' });
  const { stats, isLoading } = useDashboardStats({
    queryKey,
    ...filter,
  });

  const rows = useMemo(() => {
    const grouped = _.groupBy(stats, 'mainCode');
    const transformedItems = _.map(grouped, (group) => {
      return {
        mainCode: group[0].mainCode,
        name: group[0].mainName,
        values: _.reduce(
          group,
          (result, item) => {
            // eslint-disable-next-line no-param-reassign
            result[item.secondCode] = { total: item.total, percent: item.percent };
            return result;
          },
          {},
        ),
      };
    });
    const getSorted = () => {
      if (sort.type === 'DESC') {
        return _.reverse(_.sortBy(transformedItems, [`values.[${sort.code}].total`]));
      }
      if (sort.type === 'ASC') {
        return _.sortBy(transformedItems, [`values.[${sort.code}].total`]);
      }
      return transformedItems;
    };

    return _.map(getSorted(), (item, index) => {
      const getFormatted = (category) => {
        const value = item?.values?.[category.code];
        return category.showPercentage ? `${value?.total ?? 0}, ${value.percent}%` : value?.total ?? 0;
      };
      return (
        <Table.Tr
          key={`${item.mainCode}_table_item`}
          className="text-gray-900 font-medium text-sm odd:bg-white even:bg-gray-50"
        >
          <Table.Td className="py-4" align="center">
            {index + 1}
          </Table.Td>
          <Table.Td
            className={classNames('py-4 sticky left-0', {
              'bg-white': scrolled,
            })}
          >
            {item.name}
          </Table.Td>
          {_.map(categoryOptions, (category) => {
            return (
              <Table.Td
                key={`${item.mainName}_${category.code}`}
                align="left"
                className="text-gray-500 font-normal p-4"
              >
                {getFormatted(category)}
              </Table.Td>
            );
          })}
        </Table.Tr>
      );
    });
  }, [stats, sort, categoryOptions, scrolled]);

  const skeletons = useMemo(() => {
    return (
      <>
        <RowSkeleton categories={categoryOptions} />
        <RowSkeleton categories={categoryOptions} />
        <RowSkeleton categories={categoryOptions} />
      </>
    );
  }, [categoryOptions]);
  const toggleSort = useCallback((categoryCode) => {
    setSort((prev) => {
      const current = prev?.code === categoryCode;
      if (current) {
        return { code: prev.code, type: prev.type === 'DESC' ? 'ASC' : 'DESC' };
      }
      return { code: categoryCode, type: 'DESC' };
    });
  }, []);

  return (
    <Paper shadow="xs" radius="6px" className="px-4 py-6 w-full">
      <div className="flex flex-col space-y-4">
        <ChartTitle title={t('stats.titles.institutions-top')} />
        <Paper
          radius="8px"
          shadow="none"
          withBorder
          className={classNames({ 'overflow-hidden': scrollable, 'w-max': !scrollable })}
        >
          <Table.ScrollContainer
            minWidth="70vw"
            onScrollPositionChange={({ x }) => {
              setScrolled(x !== 0);
            }}
          >
            <Table highlightOnHover withRowBorders={false} classNames={{ th: 'font-medium py-3', thead: 'border-b' }}>
              <Table.Thead className="bg-gray-50 text-gray-500 text-xs px-6 py-3">
                <Table.Tr>
                  <Table.Th className={classNames('text-center w-14')}>#</Table.Th>
                  <Table.Th className="sticky left-0 bg-gray-50 pr-0 sm:pr-52">
                    {_.toUpper(t('stats.institution-name'))}
                  </Table.Th>
                  {_.map(categoryOptions, (category) => {
                    return (
                      <Table.Th key={category.code} className="text-center">
                        <UnstyledButton className="w-max float-left" onClick={() => toggleSort(category.code)}>
                          <div className="flex space-x-2 text-xs items-center">
                            <Tooltip label={category.label ?? category.name}>
                              <div className="text-ellipsis break-words line-clamp-1">
                                {_.toUpper(category.shortName)}
                              </div>
                            </Tooltip>
                            <div className="w-5 h-5 flex-none flex items-center justify-center bg-slate-200 rounded-sm">
                              {sort?.code === category.code && sort.type === 'DESC' ? (
                                <IconChevronDown className="size-4" />
                              ) : (
                                <IconChevronUp className="size-4" />
                              )}
                            </div>
                          </div>
                        </UnstyledButton>
                      </Table.Th>
                    );
                  })}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{isLoading ? skeletons : rows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
          {!isLoading && _.isEmpty(stats) && <EmptyState message={t('messages.no-results')} className="w-full" />}
        </Paper>
      </div>
    </Paper>
  );
}
InstitutionsTopStats.propTypes = {
  filter: PropTypes.object,
  queryKey: PropTypes.string.isRequired,
  scrollable: PropTypes.bool,
};
InstitutionsTopStats.defaultProps = {
  filter: {},
  scrollable: true,
};

export default InstitutionsTopStats;

RowSkeleton.propTypes = {
  categories: PropTypes.array.isRequired,
};
