import { Paper, Collapse, UnstyledButton, Table, Spoiler } from '@mantine/core';
import PropTypes from 'prop-types';

import { IconChevronDown } from '@tabler/icons-react';
import _ from 'lodash';
import { useDisclosure } from '@mantine/hooks';
import classNames from 'classnames';

export default function ReportSection({ section, index }) {
  const [opened, { toggle }] = useDisclosure(false);
  const showIndex = index > 0;
  return (
    <div className="flex flex-col space-y-4">
      <UnstyledButton className="flex w-full items-center" onClick={toggle}>
        <div className="flex flex-col flex-grow space-y-2 pr-4">
          <span className="text-xl text-gray-900 font-semibold">
            {showIndex ? `${index}.` : ''} {section.name}
          </span>
          <span className="text-md text-gray-400 max-w-3/4">{section.description}</span>
        </div>
        <div>
          <IconChevronDown
            className={classNames('size-4 transform transition-transform duration-300', { 'rotate-180': opened })}
          />
        </div>
      </UnstyledButton>
      <Collapse in={opened}>
        <SectionBody fields={section.fields} />
      </Collapse>
    </div>
  );
}

function SectionBody({ fields }) {
  const rows = _.map(fields, (field) => {
    const displayAnswer = _.find(field.uiHintSource, (item) => item.code === field.value);
    const isText = !field.uiHint;
    return (
      <Table.Tr key={field.code}>
        <Table.Td>
          <div className="flex flex-col space-y-2">
            <div className="font-semibold">{field.name}</div>
            <div className="text-gray-600">
              <Spoiler
                classNames={{ control: 'text-sm mt-1 text-gray-400' }}
                maxHeight={60}
                showLabel="Vezi mai mult"
                hideLabel="Ascunde"
              >
                {field.description}
              </Spoiler>
            </div>
          </div>
        </Table.Td>
        <Table.Td>{isText ? field.value : displayAnswer?.name ?? 'Fără răspuns'}</Table.Td>
      </Table.Tr>
    );
  });
  return (
    <Paper withBorder shadow="xs">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Întrebare</Table.Th>
            <Table.Th>Răspuns</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Paper>
  );
}
SectionBody.propTypes = {
  fields: PropTypes.array.isRequired,
};
ReportSection.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number,
};
ReportSection.defaultProps = {
  index: -1,
};
